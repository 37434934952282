import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Input,
} from "@mui/joy";
import {
  AllowanceData,
  convertToDecimal,
  formatWithDecimals,
  getNetworkName,
} from "../../../app/utils";
import { ModalTemplate } from "./Modal";
import { useMakeTransferMutation } from "../../../app/services/admin";
import { useDispatch } from "react-redux";
import { setNotify } from "../../notifications/notifySlice";

interface AllowanceCardProps {
  data: AllowanceData;
}

const AllowanceCard: React.FC<AllowanceCardProps> = ({ data }) => {
  const { AvailableAllowance, Asset } = data;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const dispatch = useDispatch();

  const [
    makeTransfer,
    {
      data: transferData,
      isLoading: transferLoading,
      isSuccess: transferSuccess,
      isError: transferError,
    },
  ] = useMakeTransferMutation();

  useEffect(() => {
    if (transferSuccess) {
      dispatch(
        setNotify({
          message: "Transfer sended!",
          type: "success",
          duration: 2000,
        })
      );

      setOpenModal(false);
    }

    if (transferError) {
      dispatch(
        setNotify({
          message: "Transfer error!",
          type: "danger",
          duration: 2000,
        })
      );
    }
  }, [transferSuccess, transferError]);

  return (
    <Card>
      <ModalTemplate
        open={openModal}
        setOpen={setOpenModal}
        title="Enter amount for transfer"
      >
        <form
          onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            const formElements = new FormData(event.currentTarget);
            const data = Object.fromEntries(formElements.entries());

            if (
              Number(data?.Amount) >
              formatWithDecimals(
                AvailableAllowance.CurrentAllowance,
                Asset.Decimal
              )
            ) {
              dispatch(
                setNotify({
                  message: "amount > current allowance",
                  type: "danger",
                  duration: 2000,
                })
              );

              return;
            }

            if (
              Number(data?.Amount) >
              formatWithDecimals(
                AvailableAllowance.CurrentBalance,
                Asset.Decimal
              )
            ) {
              dispatch(
                setNotify({
                  message: "amount > balance",
                  type: "danger",
                  duration: 2000,
                })
              );

              return;
            }

            await makeTransfer({
              Amount: convertToDecimal(String(data?.Amount), Asset.Decimal),
              AllowanceId: AvailableAllowance.ID,
            });
          }}
        >
          <FormControl>
            <FormLabel>Amount</FormLabel>
            <Input
              name="Amount"
              type="number"
              required
              slotProps={{ input: { min: 0, step: "any" } }}
              onFocus={(e) =>
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false }
                )
              }
              defaultValue={
                Number(AvailableAllowance.CurrentBalance) >
                Number(AvailableAllowance.CurrentAllowance)
                  ? formatWithDecimals(
                      AvailableAllowance.CurrentAllowance,
                      Asset.Decimal
                    )
                  : formatWithDecimals(
                      AvailableAllowance.CurrentBalance,
                      Asset.Decimal
                    )
              }
            />
          </FormControl>

          <Button
            type="submit"
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            loading={transferLoading}
          >
            Make transfer
          </Button>
        </form>
      </ModalTemplate>
      <CardContent
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box>
          {" "}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Allowance Details
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>ID:</strong> {AvailableAllowance.ID}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Address:</strong> {AvailableAllowance.Address}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Balance at Creation:</strong>{" "}
                {formatWithDecimals(
                  AvailableAllowance.BalanceAtCreated,
                  Asset.Decimal
                )}{" "}
                {Asset.Name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Current Balance:</strong>{" "}
                {formatWithDecimals(
                  AvailableAllowance.CurrentBalance,
                  Asset.Decimal
                )}{" "}
                {Asset.Name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Current Allowance:</strong>{" "}
                {formatWithDecimals(
                  AvailableAllowance.CurrentAllowance,
                  Asset.Decimal
                )}{" "}
                {Asset.Name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Network:</strong>{" "}
                {getNetworkName(AvailableAllowance.NetworkId)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Asset:</strong> {Asset.Name}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Button
            style={{ width: "100%", height: "100%" }}
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Make transfer
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AllowanceCard;
