import {
  Box,
  Typography,
} from "@mui/joy";
import Layout from "../features/components/Layout";
import { useEffect } from "react";
import { useGetAvailableAllowanceMutation } from "../app/services/admin";
import AllowanceCard from "../features/components/ui/AllowanceCard";

export default function Main() {
  const [getAllowanceData, { data: allowanceData, isLoading, isSuccess }] =
    useGetAvailableAllowanceMutation();

  useEffect(() => {
    getAllowanceData();
  }, [getAllowanceData]);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          my: 1,
          gap: 1,
          flexWrap: "wrap",
          "& > *": {
            minWidth: "clamp(0px, (500px - 100%) * 999, 100%)",
            flexGrow: 1,
          },
        }}
      >
        <Box>
          {isLoading ? (
            <Typography>Loading...</Typography>
          ) : (
            <Typography>
              {isSuccess ? (
                <Box>
                  {allowanceData?.map((data: any) => (
                    <AllowanceCard data={data} />
                  ))}
                </Box>
              ) : (
                "Error loading data"
              )}
            </Typography>
          )}
        </Box>
      </Box>
    </Layout>
  );
}
