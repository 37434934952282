import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

const baseQueryWithAuth = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
});

export const customBaseQuery = async (
  args: any,
  api: any,
  extraOptions: any
) => {
  const login = Cookies.get("login");
  const password = Cookies.get("password");

  let adjustedArgs = typeof args === "string" ? { url: args } : { ...args };

  if (login && password) {
    if (adjustedArgs.method === "GET" || !adjustedArgs.method) {
      adjustedArgs.params = {
        ...adjustedArgs.params,
        login,
        password,
      };
    } else {
      adjustedArgs.body = {
        ...adjustedArgs.body,
        Login: login,
        Password: password,
      };
    }
  }

  return baseQueryWithAuth(adjustedArgs, api, extraOptions);
};

export const saveToCookies = (key: string, value: string) => {
  Cookies.set(key, value);
};

export const getFromCookies = (key: string) => {
  return Cookies.get(key);
};

export const removeUser = () => {
  Cookies.remove("login");
  Cookies.remove("password");
};

export interface AvailableAllowance {
  ID: number;
  Address: string;
  BalanceAtCreated: string;
  CurrentBalance: string;
  CurrentAllowance: string;
  NetworkId: number;
  AssetId: number;
  CreatedAt: number;
}

export interface Asset {
  ID: number;
  Name: string;
  Address: string;
  Decimal: number;
  NetworkId: number;
  Price: string;
}

export interface AllowanceData {
  AvailableAllowance: AvailableAllowance;
  Asset: Asset;
}

// Функция для форматирования числа с учетом десятичных знаков
export const formatWithDecimals = (value: string, decimals: number): number => {
  const number = BigInt(value);
  const divisor = BigInt(10 ** decimals);
  const integerPart = number / divisor;
  const fractionalPart = number % divisor;

  const res = `${integerPart}.${fractionalPart
    .toString()
    .padStart(decimals, "0")}`;

  return parseFloat(res);
};

export const convertToDecimal = (formattedValue: string, decimals: number): string => {
  const [integerPart, fractionalPart = ""] = formattedValue.split(".");
  const cleanIntegerPart = integerPart.replace(/,/g, "");
  const paddedFractionalPart = fractionalPart
    .padEnd(decimals, "0")
    .slice(0, decimals);
  const rawValue = (cleanIntegerPart + paddedFractionalPart).replace(/^0+/, "");

  return rawValue || "0";
};

// Функция для определения названия сети
export const getNetworkName = (networkId: number): string => {
  switch (networkId) {
    case 2:
      return "Tron";
    case 1:
      return "BSC";
    default:
      return "Unknown";
  }
};
